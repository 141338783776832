import React, { FC } from 'react';
import { defaultInputValues, MAX_WORDS_FOR_MODEL } from '../../utils/prompt';
import GptParameterSection from './GptParameterSection';

interface Props {
  gptModel: string;
  temperature: number;
  topP: number;
  frequencyPenalty: number;
  presencePenalty: number;
  maximumWordsInResponse: number;
  handleChange: any;
}

const GPTParameters: FC<Props> = ({
  gptModel,
  temperature,
  topP,
  frequencyPenalty,
  presencePenalty,
  maximumWordsInResponse,
  handleChange,
}) => {
  return (
    <div className='grid lg:grid-cols-2 md:grid-cols-1 gap-3'>
      {gptModel.startsWith('o') || gptModel === 'deepseek-r1' ? null : (
        <GptParameterSection
          labelText='Temperature:'
          name='temperature'
          min='0'
          max='2'
          value={temperature ?? defaultInputValues.temperature}
          handleChange={handleChange}
        />
      )}

      {gptModel !== 'deepseek-r1' && (
        <GptParameterSection
          labelText='Top P:'
          name='topP'
          min='0'
          max='1'
          value={topP ?? defaultInputValues.topP}
          handleChange={handleChange}
        />
      )}
      
      {gptModel.startsWith('o') || gptModel === 'deepseek-r1'  ? null : (
        <>
          <GptParameterSection
            labelText='Frequency penalty:'
            name='frequencyPenalty'
            min='0'
            max='2'
            value={frequencyPenalty ?? defaultInputValues.frequencyPenalty}
            handleChange={handleChange}
          />

          <GptParameterSection
            labelText='Presence penalty:'
            name='presencePenalty'
            min='0'
            max='2'
            value={presencePenalty ?? defaultInputValues.presencePenalty}
            handleChange={handleChange}
          />
        </>
      )}

      <GptParameterSection
        labelText='Maximum words in response:'
        name='maximumWordsInResponse'
        min='1'
        max={MAX_WORDS_FOR_MODEL[gptModel]}
        step='1'
        value={
          maximumWordsInResponse ??
          defaultInputValues.maximumWordsInResponse[gptModel]
        }
        tooltipContent='Setting a smaller response length increases the chances of your prompt going through quickly, and reduces the risk of capacity issues.'
        handleChange={handleChange}
      />
    </div>
  );
};

export default GPTParameters;
