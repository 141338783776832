import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Multiselect from "react-widgets/esm/Multiselect";
import FuserContext from "../../context/FuserContext";
import { buildCategoryTree } from "../../utils/tree";
import { backendURL } from "../../constants/environmental";

let categoryTree: any[] = [];
const allCategory = { _id: '0'.repeat(24), index: 0, catName: 'All' };

const PriorityCategoriesSection = ({ isForSharedBlock }: any) => {
  const {
    toolMetadata: { categoriesPrioritisedIn, chosenCategories },
    updateToolMetadata,
  } = useContext(FuserContext);

  const [categories, setCategories] = useState<any>();

  const ancestors = (category: any): any[] => {
    return category
      ? [
          ...(category?.parentCategory
            ? ancestors(
                categories.find(
                  ({ _id }: any) => _id === category.parentCategory
                )
              )
            : []),
          category,
        ]
      : [];
  }
  
  const availableCategories = categories && (
    !chosenCategories?.length ? [allCategory] :
    [allCategory, ...chosenCategories]
      .flatMap((category: any) => ancestors(category))
      .sort((c: any, d: any) => c._id < d._id ? -1 : 1)
    );
      
  const chosenCategoryNames = categoriesPrioritisedIn?.map(
    (id: string) => categories?.find((category: any) => category._id === id).catName
  );

  useEffect(() => {
    if (isForSharedBlock) {
      return;
    }
    fetchCategories();
  }, []);

  return (
    <div className="w-full gap-2 bg-blue-200 dark:bg-neutral-800 p-2 flex flex-col items-center justify-between rounded-lg shadow-xl">
      <p style={{ alignSelf: 'flex-start' }}>
        Priority categories:
      </p>

      <Multiselect
        style={{ width: '100%' }}
        dataKey='_id'
        textField='catName'
        value={chosenCategoryNames}
        onChange={handleCatChange}
        onSelect={handleCatSelect}
        // onSearch={handleCatSearch}
        data={availableCategories?.map(({ catName }: any) => catName)}
        filter='contains'
        // listComponent={CustomListComponent}
      />
    </div>
  );


  function handleCatChange(newSelectedCategoryNames: string[]) {
    if (newSelectedCategoryNames.length >= categoriesPrioritisedIn.length) {
      return;
    }

    updateToolMetadata({
      categoriesPrioritisedIn: newSelectedCategoryNames.map(
        name => categories.find(({ catName }: any) => catName === name)._id
      )
    });
  }

  function handleCatSelect(selectedName: any) {
    updateToolMetadata({
      categoriesPrioritisedIn: [
        ...categoriesPrioritisedIn,
        categories.find(({ catName }: any) => selectedName === catName)._id,
      ],
    });
  }

  async function fetchCategories() {
    const res = await axios.get(`${backendURL}/categories/all`);
    let loadedCategories = res.data;

    loadedCategories = [allCategory, ...loadedCategories];

    categoryTree = buildCategoryTree(loadedCategories);
    setCategories(loadedCategories);
  }
}

export default PriorityCategoriesSection;
