import React, { FC, useContext } from 'react';

import FuserMainOptions from './FuserMainOptions';
import BlocksDisplay from './BlocksDisplay';
import NoBlocksDisplay from './NoBlocksDisplay';
import FuserContext from '../../context/FuserContext';





const FuserMain: FC<{}> = () => {
  const { blocks, runnerMode } = useContext(FuserContext);

    let has_prompt = false;
    let has_multiple_promtps = false;
    for (let i = 0; i<blocks?.length;i++){
      if (blocks?.[i].type == "blockdiv-prompt") {
        if (has_prompt) has_multiple_promtps = true;
        has_prompt = true;
      }
    }

    const hint_content = (
      <div className="bg-purple-100 text-purple-800 border border-black p-4">
        <p>Hints:</p>
        {blocks?.length === 0 && (
          <p>
            Add your first block, usually this will be a &quot;User Question&quot; block
          </p>
        )} 
        {blocks?.[blocks?.length-1] && blocks?.[blocks?.length-1].type == "blockdiv-question" && (
          <p>
            Type in a question for the user
            <br />
            and in the white box put in a test answer to help you test your tool, and press &quot;save&quot; underneath
          </p>
        )}
        {blocks?.length==1 && blocks?.[blocks?.length-1].type == "blockdiv-question" && <p>To add a second block use the &quot;+&quot; in the bottom right of the previous block</p>}
        {!has_prompt && blocks?.length>0 && blocks?.[blocks?.length-1].type == "blockdiv-question" && (<p>Add a prompt block when you want your tool to talk to the AI</p>)}
        {has_prompt && !has_multiple_promtps && blocks?.[blocks?.length-1].type == "blockdiv-prompt" && (
          <p>In the prompt block you can type in your insturctions for the AI<br/>To include the output from another block, type &quot;@Block0&quot; for the first block<br/>Press the &quot;Test&quot; button to see how the AI replies
          <br/>An example of a prompt:
          <div className='border border-black p-4'>The user&#39;s name is @Block0
          <br />Please make up a short rhyme to greet them</div>
          </p>)}
        
        {has_prompt && !has_multiple_promtps && <p><br/>Tools that have 2 or more prompt blocks tend to be better quality</p>}
        {has_prompt && has_multiple_promtps && <p>You can combine multiple blocks to build your prompt e.g:
          <div className='border border-black p-4'>The user&#39;s wealth goal is:<br/>` @Block0 `
          <br />Their time period is: @Block1 years
          <br />They are starting with this much money: @Block2
          <br />Please generate a financial plan including savings, stock market investments, and bonds.
          <br />Example format: ..... </div>
          (You would be better choosing a model like o1 for this sort of prompt)
          </p>}
      </div>
    ); 
    // separate the instructions out so it just says add question block, then add question, then test answer, then next block
    // add hints for different block types
    // tell them how to publish and on five or more blocks
    // tell them how to save after 3 blocks



  return (
    <div
      className='flex-grow flex-col pb-4 px-2 w-full gap-2'
      id='blocks-container'
    >
      <FuserMainOptions />
      <div className='blocks-height px-4 h-full overflow-y-scroll bg-neutral-100 dark:bg-neutral-800 shadow-3xl rounded-xl mt-4 lg:pb-96'>
        {blocks?.length === 0 ? <NoBlocksDisplay /> : <BlocksDisplay />}

        {!runnerMode && (<><br />{hint_content}
          {/* {console.log(blocks)} */}
          </>)}

          
        {!runnerMode && (
          <>
            <p>
            <br/>More hints:<p>Use the running man icon to see and test your tool the way a user will<br/>
      Save your tool frequently using the &quot;save&quot; button on the right at any time<br />
      Hover over the tool tips &quot;?&quot; in each block for more info</p>

  
              <br />- Check out the{' '}
              <a
                href='/maker-help'
                target='_blank'
                style={{ color: 'darkblue', textDecoration: 'underline' }}
              >
                help
              </a>{' '}
              page for more info, the video below also has useful information
            </p>
            <br />
            <div className='flex justify-start'>
              <iframe
                className='max-w-560'
                width='560'
                height='315'
                src='https://www.youtube.com/embed/21zYepA4PvE?si=HjCv2coUYW-UkNC0'
                title='YouTube video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              ></iframe>
            </div>
            <p><b>
                Would you like us to make a custom tool for you,
                <br />
                to help automate your business - contact{' '}
                <a
                  href='mailto:mike@skillfusion.ai'
                  style={{ textDecoration: 'underline' }}
                >
                  mike@skillfusion.ai
                </a>
                .
                <br />
                We can build tools quickly and affordably with this no code
                builder.
              </b>
              <br />
              <br /></p>
          </>
        )}
      </div>
    </div>
  );
};

export default FuserMain;
