import React, { useContext } from "react";
import { useAuthUser } from "react-auth-kit";
import FuserContext from "../../context/FuserContext";

function MarkRecommendedSection() {
  const user = useAuthUser()();

  const {
    toolMetadata: { isRecommended },
    updateToolMetadata,
  } = useContext(FuserContext);

  return user?.loggedin !== "false" ? null : (
    <div className="w-full">
      <label>
        Mark as recommended:{" "}
        <input
          type="checkbox"
          checked={isRecommended}
          onChange={({ target: { checked } }) =>
            updateToolMetadata({ isRecommended: checked })
          }
        />
      </label>
    </div>
  );
}

export default MarkRecommendedSection;
