import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import FuserLoader from '../../containers/FuserPage/FuserLoader';
import BlockProps from '../../models/BlockProps';
import MyToolTips from '../../components/MyTooltip';
import FuserContext from '../../context/FuserContext';
import Block from '../../models/Block';
import useBlockRunner from '../../hooks/useBlockRunner';
import AutocompleteTextarea from '../../containers/FuserPage/AutocompleteTextarea';
import { testButtonStyles } from '../../constants/styles';
import { updateAtIndexRun, updateAtIndex } from '../../utils/array';
import { tidyUpResponse } from '../../utils/fuser';
import { getBlockIndicesToModify, modifyBlockData, processBlockData, sendBlockInputToChatBlock } from '../../utils/blockProcessing';
import AutosizeTextarea from '../../containers/FuserPage/AutosizeTextarea';

const ProcessingBlock: FC<BlockProps> = ({
  isLoading,
  setIsLoading,
  index,
  resultHtml,
  block,
  handleChange,
  updateBlocks,
}) => {
  const {
    blockStyles,
    runnerMode,
    blocks,
    setBlocks,
    setActivityLog,
    textAreaRefs,
    spreadsheetRefs,
    saveRunnerModeBlockDataWithoutRunnerIndex
  } = useContext(FuserContext);

  const [responseDescription, setResponseDescription] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useBlockRunner(onProcessingSaveClick, index);

  useEffect(() => setStatusMessage(''), [runnerMode]);

  const {
    inputToProcess,
    selectValue,
    referenceModifier,
    customSeparator,
    customMatchPattern,
    customMatchFlags,
    newValue,
    appendSecondOperand,
    checkedRadioValue,
    changeAmountString,
    cropAmountString,
    numberOfWordsToRemoveFromStart,
    removeFirstMatchPattern,
    removeFirstMatchFlags,
    captureGroupIndex,
    chatBlockNumber,
    numberOfCharacters
  } = block.data;

  const radioGroupName = `radio-group-${index}`;

  return (
    <FuserLoader
      name='Processing Block'
      loading={isLoading}
    >
      {runnerMode ? (
        <>
          <div>Processing block</div>
          <p>{statusMessage}</p>
        </>
      ) : (
        <div
          className={blockStyles}
          key={index}
        >
          <label
            className='text-xs'
            id='prompt-textarea'
          >
            Input to be processed:{' '}
            <MyToolTips
              content='
            <p>This processing block will split up content so you can use it in separate prompts.</p> 

            <p>For instance you can ask chatGPT for a numbered ten point list for a long article. You would then use this block to split that up.</p>
            
            <p>Then any block where you use this output would run ten times, once for each item in the list.</p>
            
            <p>You can also split lists in to sub lists. E.g for a non fiction book you may do a 12 chapter plan, then a 6 point plan for each chapter. So then you would have 12 x 6 point plan you can then use in the prompt block to write the book.</p>
            
            <p>You can also use it to split content up that you may want chatGPT to summarise, translate, or rewrite.</p>
            '
              tipID='block-types'
              datatooltipplace='below'
            />
          </label>

          <AutocompleteTextarea
            block={block}
            index={index}
            onChange={handleChange}
            className='w-full prompt-textarea bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
            name='inputToProcess'
            value={inputToProcess || ''}
          />

          <br />
          <br />

          <label
            id='process-select'
            className='flex gap-2 items-center'
          >
            <input
              type='radio'
              id='radio-process'
              name={radioGroupName}
              value={'process'}
              onChange={handleChange}
              checked={'process' === checkedRadioValue}
            />
            Process and output:
          </label>

          <select
            className='text-xs bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
            id='process-select'
            name='selectValue'
            value={selectValue}
            onChange={handleSelectChange}
          >
            <option value='numbered-list'>Numbered output to actual list</option>
            <option value='line-breaks'>Separate text by line</option>
            <option value='paragraph'>Separate text by paragraph</option>
            <option value='comma'>Comma separated to actual list</option>
            <option value='400words'>400 Words to nearest paragraph</option>
            <option value='800words'>800 Words to nearest paragraph</option>
            <option value='split-x-characters'>Split every X characters</option>
            <option value='custom-separator'>Custom separator</option>
            <option value='custom-match'>Custom match</option>
            <option value='find-remove'>Find and replace</option>

          </select>

          {selectValue === 'split-x-characters' && (
            <div className='w-full flex items-center gap-2'>
              <label
                htmlFor='customSeparator'
                className='w-max'
              >
                Enter number of characters here (digits only):
              </label>
              <AutocompleteTextarea
                autosize={true}
                block={block}
                index={index}
                onChange={handleRadioTextareaChange('selectValue')}
                containerClassName='grow flex'
                className='w-24 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                name='numberOfCharacters'
                value={numberOfCharacters ?? ''}
              />
            </div>
          )}

          {selectValue === 'custom-separator' && (
            <div className='w-full flex items-center gap-2'>
              <label
                htmlFor='customSeparator'
                className='w-max'
              >
                Enter custom separator here:
              </label>
              <AutocompleteTextarea
                autosize={true}
                block={block}
                index={index}
                onChange={handleRadioTextareaChange('selectValue')}
                containerClassName='grow flex'
                className='w-full bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                name='customSeparator'
                value={customSeparator ?? ''}
              />
            </div>
          )}

          {selectValue === 'custom-match' && (
            <div className='flex flex-col gap-2'>
              <label
                className='flex flex-col gap-2'
                htmlFor='customMatchPattern'
              >
                Enter custom match pattern here: (this field is interpreted as
                regular expression syntax){' '}
                <AutocompleteTextarea
                  autosize={true}
                  block={block}
                  index={index}
                  className='w-1/3 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='customMatchPattern'
                  value={customMatchPattern ?? ''}
                  onChange={handleRadioTextareaChange('selectValue')}
                />
              </label>
              <label
                className='flex flex-col gap-2'
                htmlFor='customMatchFlags'
              >
                Enter flags here (e.g. for global and case insensitive write
                gi):{' '}
                <AutosizeTextarea
                  block={block}
                  className='w-1/6 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='customMatchFlags'
                  value={customMatchFlags ?? ''}
                  onChange={handleRadioTextareaChange('selectValue')}
                />
              </label>
              <label
                className='flex flex-col gap-2'
                htmlFor='customMatchFlags'
              >
                (Optional): Enter the number of the capturing group you want to
                extract
                <AutosizeTextarea
                  className='w-1/6 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='captureGroupIndex'
                  value={captureGroupIndex ?? ''}
                  onChange={handleRadioTextareaChange('selectValue')}
                  block={block}
                />
              </label>
            </div>
          )}

          {selectValue === 'find-remove' && (
            <div className='flex flex-col gap-2'>
              <label className='flex gap-2 items-center'>
                <input
                  type='checkbox'
                  onChange={() => {
                    block.data.removeEmptyElements =
                      !block.data.removeEmptyElements;
                    updateBlocks();
                  }}
                  checked={block.data.removeEmptyElements}
                />
                Remove empty elements (only applicable if the input is an array)
              </label>
              <label
                className='flex flex-col gap-2'
                htmlFor='customMatchPattern'
              >
                Enter custom match pattern here: (this field is interpreted as
                regular expression syntax){' '}
                <AutosizeTextarea
                  className='w-1/3 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='customMatchPattern'
                  value={customMatchPattern ?? ''}
                  onChange={handleRadioTextareaChange('selectValue')}
                  block={block}
                />
              </label>
              <label
                className='flex flex-col gap-2'
                htmlFor='customMatchFlags'
              >
                Enter flags here (e.g. for global and case insensitive write
                gi):{' '}
                <AutosizeTextarea
                  className='w-1/6 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='customMatchFlags'
                  value={customMatchFlags ?? ''}
                  onChange={handleRadioTextareaChange('selectValue')}
                  block={block}
                />
                Enter new value here ($& inserts the match, $n inserts the nth
                capturing group):
                <AutocompleteTextarea
                  autosize={true}
                  block={block}
                  index={index}
                  className='w-1/6 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='newValue'
                  value={newValue ?? ''}
                  onChange={handleRadioTextareaChange('selectValue')}
                />
              </label>
            </div>
          )}

          <label
            id='modifier-select'
            className='flex gap-2 items-center'
          >
            <input
              type='radio'
              id='radio-modify-references'
              name={radioGroupName}
              value={'modify-references'}
              onChange={handleChange}
              checked={'modify-references' === checkedRadioValue}
            />
            Modify references
          </label>

          <div className='flex gap-2 flex-wrap items-center'>
            <select
              className='grow text-xs bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner'
              id='modifier-select'
              name='referenceModifier'
              value={referenceModifier}
              onChange={handleSelectChange}
            >
              <option value='increment'>Increase by</option>
              <option value='decrement'>Decrease by</option>
              <option value='multiply-by'>Multiply by</option>
              <option value='append'>Append</option>
              <option value='append-list'>Append Lists</option>
              <option value='clear'>Clear</option>
              <option value='keep-start'>Keep the first</option>
              <option value='keep-end'>Keep the last</option>
              <option value='remove-words-from-start-and-set-as-response'>
                Remove words from start and set as the response
              </option>
              <option value='remove-first-match-and-set-as-response'>
                Remove first match and set as the response
              </option>
              <option value='set-values-of-spreadsheet-range'>
                Set values of spreadsheet range
              </option>
              <option value='send-to-chat-block-temporary'>
                {"Send extra info to chat block (don't add to chat history)"}{' '}
              </option>
              <option value='send-to-chat-block-permanent'>
                Send extra info to chat block (add to chat history)
              </option>
              <option value='send-to-chat-block-permanent-dont-process'>
                {
                  "Send extra info to chat block (add to chat history and don't send to openAI)"
                }
              </option>
            </select>

            {referenceModifier === 'set-values-of-spreadsheet-range' && (
              <>
                <label
                  className='flex gap-2 items-center basis-1/2'
                  htmlFor='newSpreadsheetValues'
                >
                  <p className='text-sm'>Enter the new value:</p>
                  <AutocompleteTextarea
                    autosize={true}
                    block={block}
                    index={index}
                    onChange={handleRadioTextareaChange('referenceModifier')}
                    containerClassName='flex items-center'
                    className='bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                    name='newSpreadsheetValues'
                    value={block.data.newSpreadsheetValues ?? ''}
                  />
                </label>
                <label
                  className='flex gap-2 items-center basis-1/2'
                  htmlFor='newSpreadsheetValues'
                >
                  
                  <input
                    type='checkbox'
                    checked={block.data.transposeNewValues}
                    onChange={({ target: { checked } }) => {
                      block.data.transposeNewValues = checked;
                      updateBlocks();
                    }}
                  /> <span className='text-sm'>Transpose new values?</span>
                </label>
                <div className='flex items-center gap-2'>
                  <label
                    className='flex gap-2 items-center basis-1/2'
                    htmlFor='spreadsheetRange'
                  >
                    <p className='text-sm'>Enter the range to update: </p>
                    <AutosizeTextarea
                      className='bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                      name='spreadsheetRange'
                      value={block.data.spreadsheetRange ?? ''}
                      onChange={handleRadioTextareaChange('referenceModifier')}
                      block={block}
                    />
                  </label>
                  <label
                    className='flex gap-2 items-center basis-1/2'
                    htmlFor='newColumnHeading'
                  >
                    <p className='text-sm'>Add column heading (optional)</p>
                    <AutosizeTextarea
                      className='bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                      name='newColumnHeading'
                      value={block.data.newColumnHeading ?? ''}
                      onChange={handleRadioTextareaChange('referenceModifier')}
                      block={block}
                    />
                  </label>
                </div>
              </>
            )}
              
            {['increment', 'decrement', 'multiply-by'].includes(
              referenceModifier
            ) && (
              <label
                className='flex gap-2 items-center'
                htmlFor='changeAmountString'
              >
                <p className='text-sm'>Enter amount: </p>
                <AutosizeTextarea
                  className='bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='changeAmountString'
                  value={changeAmountString ?? ''}
                  onChange={handleRadioTextareaChange('referenceModifier')}
                  block={block}
                />
              </label>
            )}

            {['keep-start', 'keep-end'].includes(referenceModifier) && (
              <>
                <label
                  className='flex gap-2 items-center'
                  htmlFor='cropAmountString'
                ></label>
                <AutosizeTextarea
                  className='bg-transparent rounded-xl text-sm border border-black-400 shadow-inner'
                  name='cropAmountString'
                  value={cropAmountString ?? ''}
                  onChange={handleRadioTextareaChange('referenceModifier')}
                  block={block}
                />
                <p className='text-sm'>characters</p>
              </>
            )}

            {['append', 'append-list'].includes(referenceModifier)  && (
              <label
                className='flex gap-2 items-center'
                htmlFor='appendSecondOperand'
              >
                <p className='text-sm'>Enter second item to append: </p>
                <AutocompleteTextarea
                  autosize={true}
                  block={block}
                  index={index}
                  onChange={handleRadioTextareaChange('referenceModifier')}
                  containerClassName='flex items-center'
                  className='bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='appendSecondOperand'
                  value={appendSecondOperand ?? ''}
                />
              </label>
            )}

{/* {referenceModifier === 'append-list' && (
              <label
                className='flex gap-2 items-center'
                htmlFor='appendSecondOperand'
              >
                <p className='text-sm'>Enter second item to append: </p>
                <AutocompleteTextarea
                  autosize={true}
                  block={block}
                  index={index}
                  onChange={handleRadioTextareaChange('referenceModifier')}
                  containerClassName='flex items-center'
                  className='bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='appendSecondOperand'
                  value={appendSecondOperand ?? ''}
                />
              </label>
            )} */}

            {referenceModifier ===
              'remove-words-from-start-and-set-as-response' && (
              <label
                className='flex gap-2 items-center'
                htmlFor='numberOfWordsToRemoveFromStart'
              >
                <p className='text-sm'>Number of words to remove: </p>
                <AutocompleteTextarea
                  autosize={true}
                  block={block}
                  index={index}
                  onChange={handleRadioTextareaChange('referenceModifier')}
                  containerClassName='flex items-center'
                  className='bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='numberOfWordsToRemoveFromStart'
                  value={numberOfWordsToRemoveFromStart ?? ''}
                />
              </label>
            )}

            {referenceModifier === 'remove-first-match-and-set-as-response' && (
              <div className='flex flex-col gap-2'>
                <label
                  className='flex gap-2 items-center'
                  htmlFor='removeFirstMatchPattern'
                >
                  <p className='text-sm'>Match to remove: </p>
                  <AutocompleteTextarea
                    autosize={true}
                    block={block}
                    index={index}
                    onChange={handleRadioTextareaChange('referenceModifier')}
                    containerClassName='flex items-center'
                    className='bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                    name='removeFirstMatchPattern'
                    value={removeFirstMatchPattern ?? ''}
                  />
                </label>
                <label
                  className='flex flex-col gap-2'
                  htmlFor='removeFirstMatchFlags'
                >
                  Enter flags here (e.g. for global and case insensitive write
                  gi):{' '}
                  <AutosizeTextarea
                    className='w-1/6 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                    name='removeFirstMatchFlags'
                    value={removeFirstMatchFlags ?? ''}
                    onChange={handleRadioTextareaChange('referenceModifier')}
                    block={block}
                  />
                </label>
              </div>
            )}

            {referenceModifier?.startsWith('send-to-chat-block') && (
              <label
                className='flex gap-2 items-center'
                htmlFor='chatBlockNumber'
              >
                <p className='text-sm'>
                  Enter chat block number here (it will be sent to the first
                  chat block if left blank):
                </p>
                <AutocompleteTextarea
                  autosize={true}
                  block={block}
                  index={index}
                  onChange={handleChange}
                  containerClassName='flex items-center'
                  className='bg-transparent rounded-xl text-sm border border-black-100 shadow-inner w-16'
                  name='chatBlockNumber'
                  value={chatBlockNumber}
                />
              </label>
            )}
          </div>

          {errorMessage && <p>{errorMessage}</p>}
          
          <button
            onClick={onProcessingSaveClick}
            className={testButtonStyles}
          >
            TEST
          </button>

          <div
            className='overflow-x-auto'
            dangerouslySetInnerHTML={{ __html: resultHtml }}
          />
          <p>{responseDescription}</p>
        </div>
      )}
    </FuserLoader>
  );

  async function onProcessingSaveClick() {
    setIsLoading(true);
    setStatusMessage('');
    setErrorMessage('');

    let blockUpdater;

    try {
      if (checkedRadioValue === 'modify-references') {
        const { referenceModifier } = block.data;

        blockUpdater = (blocks: any) => {
          blocks[index].updatedBlock = runnerMode;
          
          const { blocks: newBlocks, returnedValue } = (
            referenceModifier.startsWith('send-to-chat-block') ?
            sendBlockInputToChatBlock(block, blocks) :
            modifyBlockData({ block, blocks, spreadsheetRefs })
          );
          
          const modifiedBlockIndices = getBlockIndicesToModify(block, blocks);
          const modifiedBlocks = modifiedBlockIndices.map((i: number) => newBlocks[i]);
          for (const block of modifiedBlocks) {
            saveRunnerModeBlockDataWithoutRunnerIndex(block);
          }
          
          if (returnedValue) {
            blocks[index].data.response = returnedValue;
          }
          setResponseDescription(
            returnedValue === undefined ? 'References modified' : ''
          );
          return newBlocks;
        };
      }
      else if (checkedRadioValue === 'process') {
        processBlockData({ block, blocks });
        blockUpdater = (blocks: Block[]) => {
          return (runnerMode ? updateAtIndexRun : updateAtIndex)(
            index, block, blocks
          );
        }
        setResponseDescription(tidyUpResponse(block.data.response));
      }
      setStatusMessage('Processing complete');

      //console.log('Processing response: ', response);
      if (!runnerMode) setBlocks(blockUpdater);
      else return blockUpdater;

      setActivityLog((prevLog: string[]) => [
        ...prevLog,
        `Saved processing block at index: ${index}`,
      ]);
    } catch (error) {
      // console.log(error)
      if (error.message) {
        setErrorMessage('Error: ' + error.message);
      }
      else {
        setErrorMessage('There was an error processing your input.')
      }
    } finally {
      setIsLoading(false);
    }
  }

  function handleSelectChange(e: ChangeEvent<HTMLSelectElement>) {
    const selectName = e.target.name;
    if (!['selectValue', 'referenceModifier'].includes(selectName)) {
      console.log(
        'select change handler called with invalid select name:',
        selectName
      );
      return;
    }
    block.data[selectName] = e.target.value;
    block.data.checkedRadioValue =
      selectName === 'selectValue' ? 'process' : 'modify-references';

    const inputToProcess = block.data.inputToProcess ?? '';

    const inputIsEnclosedInSquareBrackets =
      inputToProcess.startsWith('[') && inputToProcess.endsWith(']');

    if (
      e.target.value === 'send-to-chat-block-temporary' &&
      !inputIsEnclosedInSquareBrackets
    ) {
      block.data.inputToProcess = `[System: ${
        block.data.inputToProcess || ''
      }]`;
    }
    updateBlocks();
  }

  function handleRadioTextareaChange(selectName: string) {
    return (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (['selectValue', 'referenceModifier'].includes(selectName)) {
        block.data.checkedRadioValue =
          selectName === 'selectValue' ? 'process' : 'modify-references';
        updateBlocks();
      }
      else {
        console.log(
          'select change handler called with invalid select name:',
          selectName
        );
      }

      handleChange(e);
    };
  }
};



export default ProcessingBlock;
