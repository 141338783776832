import { createContext } from "react";

interface FuserContextProps {
  restartRunnerMode: any;
  blocks: any;
  collapsedBlocks: any;
  runnerIndex: any;
  runnerMode: any;
  isFullScreen: any;
  isJsonVisible: any;
  hasAccess: any;
  stillRunning: any;
  selectedTextareaId: any;
  selectedBlockId: any;
  setSelectedBlockId: any;
  selectedBlockReference: any;
  isLoading: any;
  activityLog: any;
  isOwner: any;
  isAuthor: any;
  userId: any;
  textAreaRefs: any;
  blockRefs: any;
  imageURLsForBlockWithId: any;
  waitingForClearUnsavedResponses: any;
  errorLoadingBlocks: any;
  toggleRunnerMode: any;
  undoChanges: any;
  redoChanges: any;
  toggleSidebar: any;
  setIsFullScreen: any;
  contentRef: any;
  toggleJsonVisibility: any;
  clearUnsavedResponses: any;
  pauseRunnerMode: any;
  resumeRunnerMode: any;
  setStillRunning: any;
  saveBlock: any;
  setSelectedBlockReference: any;
  setIsLoading: any;
  focusedTextarea: any;
  cursorPositionRef: any;
  setFocusedTextArea: any;
  setBlocks: any;
  setActivityLog: any;
  setImageURLsForBlockWithId: any;
  handleChange: any;
  handleTextareaFocus: any;
  updateBlockData: any;
  setWaitingForClearUnsavedResponses: any;
  toolId: any;
  blocksHaveLoaded: any;
  saveBlockStatusMessage: any;
  setSaveBlockStatusMessage: any;
  setRunnerIndex: any;
  setRunnerModeBlocksData: any;
  blockCancellationState: any;
  setBlockCancellationState: any;
  cancellingBlocksMemo: any;
  setCollapsedBlocks: any;
  blockToShare: any;
  setBlockToShare: any;
  shareModalOpen: any;
  setShareModalOpen: any;
  shareMessage: any;
  setShareMessage: any;
  isSidebarOpen: any;
  saveRunnerModeBlockData: any;
  runnerIndexHistory: any;
  setMinimalMode: any;
  minimalMode: any;
  blockStyles: any;
  restartQueued: any;
  anyBlocksLoading: any;
  setBlockScrollingIntoView: any;
  blockScrollingIntoView: any;
  setShowReportIssueDialog: any;
  runnerModeBlocksData: any;
  addBlock: any;
  buildModeBlocks: any;
  setBuildModeBlocks: any;
  LoadBlock: any;
  toolMetadata: any;
  updateToolMetadata: any;
  spreadsheetRefs: any;
  setSpreadsheetRefs: any;
  pricing: any;
  saveRunnerModeBlockDataWithoutRunnerIndex: any;
}

const FuserContext = createContext<FuserContextProps>({
  saveRunnerModeBlockDataWithoutRunnerIndex: null,
  pricing: null,
  spreadsheetRefs: null,
  setSpreadsheetRefs: null,
  toolMetadata: null,
  updateToolMetadata: null,
  LoadBlock: null,
  buildModeBlocks: null,
  setBuildModeBlocks: null,
  focusedTextarea: null,
  cursorPositionRef: null,
  addBlock: null,
  runnerModeBlocksData: null,
  setShowReportIssueDialog: null,
  blockScrollingIntoView: null,
  setBlockScrollingIntoView: null,
  anyBlocksLoading: null,
  restartQueued: null,
  restartRunnerMode: null,
  blockStyles: null,
  minimalMode: null,
  setMinimalMode: null,
  runnerIndexHistory: null,
  saveRunnerModeBlockData: null,
  isSidebarOpen: null,
  shareMessage: null,
  setShareMessage: null,
  shareModalOpen: null,
  setShareModalOpen: null,
  setBlockToShare: null,
  blockToShare: null,
  cancellingBlocksMemo: null,
  blockCancellationState: null,
  setBlockCancellationState: null,
  setRunnerModeBlocksData: null,
  setRunnerIndex: null,
  saveBlockStatusMessage: null,
  blocksHaveLoaded: null,
  toolId: null,
  blocks: null,
  collapsedBlocks: null,
  runnerIndex: null,
  runnerMode: null,
  isFullScreen: null,
  isJsonVisible: null,
  hasAccess: null,
  stillRunning: null,
  selectedTextareaId: null,
  selectedBlockId: null,
  setSelectedBlockId: null,
  selectedBlockReference: null,
  isLoading: null,
  activityLog: null,
  isOwner: null,
  isAuthor: null,
  userId: null,
  textAreaRefs: null,
  blockRefs: null,
  imageURLsForBlockWithId: null,
  waitingForClearUnsavedResponses: null,
  errorLoadingBlocks: null,
  contentRef: null,
  setIsFullScreen: () => {},
  setSaveBlockStatusMessage: () => {},
  setCollapsedBlocks: () => {},
  toggleRunnerMode: () => {},
  undoChanges: () => {},
  redoChanges: () => {},
  toggleSidebar: () => {},
  toggleJsonVisibility: () => {},
  clearUnsavedResponses: () => {},
  pauseRunnerMode: () => {},
  resumeRunnerMode: () => {},
  setStillRunning: () => {},
  saveBlock: () => {},
  setSelectedBlockReference: () => {},
  setIsLoading: () => {},
  setFocusedTextArea: () => {},
  setBlocks: () => {},
  setActivityLog: () => {},
  setImageURLsForBlockWithId: () => {},
  handleChange: () => {},
  handleTextareaFocus: () => {},
  updateBlockData: () => {},
  setWaitingForClearUnsavedResponses: () => {},
});

export default FuserContext;
